/**
 * @file MicroModal 呼び出し
 *
 */

import MicroModal from 'micromodal';

export default function () {
  MicroModal.init({
    disableScroll: true,
    awaitOpenAnimation: true,
    awaitCloseAnimation: true,
  });
}
