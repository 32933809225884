/**
 * @file header
 *
 */

const activities = $('#activities');
const logo = $('.l-header__logo');
const buttons = $('.l-header__reserve, .l-header__visitor');

export default function () {
  let logoTop = logo.position();
  let logoBottom = logoTop.top + logo.innerHeight();
  let footer = $('.l-footer').innerHeight();
  let docHeight = $(document).height();
  let dispHeight = $(window).height();

  $(window).on('scroll', () => {
    let scrollTop = $(window).scrollTop();

    if (activities.length) {
      let areaTop = activities.offset().top - logoBottom; // 対象エリアの上部の位置
      let areaBottom = areaTop + activities.innerHeight(); // 対象エリアの下部の位置
      if (scrollTop > areaTop && scrollTop < areaBottom) {
        $('body').addClass('is-in'); // スクロールが対象エリアに入った場合
      } else {
        $('body').removeClass('is-in'); // スクロールが対象エリアから出ている場合
      }
    }

    if (scrollTop > docHeight - dispHeight - footer) {
      buttons.addClass('is-hidden'); //footerより下にスクロールしたらis-hiddenを追加
    } else {
      buttons.removeClass('is-hidden'); //footerより上にスクロールしたらis-hiddenを削除
    }
  });
}
