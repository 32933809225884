import 'core-js/stable';
import 'regenerator-runtime/runtime';

import header from 'scripts/modules/header';
import menu from 'scripts/modules/menu';
import modal from 'scripts/modules/modal';
import vh from 'scripts/modules/vh';
import wow from 'scripts/modules/wow';

// 共通処理を登録する
const common = () => {
  $(function () {
    header();
    menu();
    modal();
    vh();
    wow();
  });
};

export default common;
