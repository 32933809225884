/**
 * @file menu
 *
 */

let scrollpos;
const trigger = $('.l-header__button');
const close = $('.p-header-nav__close');
const menu = $('.l-header__nav');
const fixedClass = 'is-fixed';
const activeClass = 'is-active';
const openedClass = 'is-open';

export default function () {
  trigger.on('click', (e) => {
    e.preventDefault();

    if ($(e.currentTarget).hasClass(activeClass)) {
      menuClose();
    } else {
      scrollpos = $(window).scrollTop();
      $('body').addClass(fixedClass).css({ top: -scrollpos });
      $('html').addClass(openedClass);
      menu.slideDown('250').addClass(activeClass);
      $(e.currentTarget).addClass(activeClass);
    }
  });

  close.on('click', () => {
    menuClose();
  });

  $('.p-header-nav__top a').on('click', () => {
    menuClose();
  });

  const menuClose = () => {
    $('body').removeClass(fixedClass).css({ top: '' });
    window.scrollTo(0, scrollpos);
    menu.slideUp('250').removeClass(activeClass);
    trigger.removeClass(activeClass);
    setTimeout(() => {
      $('html').removeClass(openedClass);
    }, 500);
  };
}
