/**
 * @file スクロールでふんわり表示
 *
 */

import WOW from 'wow.js';

export default function () {
  new WOW({
    animateClass: 'animate__animated',
    // offset: 0, // distance to the element when triggering the animation (default is 0)
    // mobile: true, // trigger animations on mobile devices (default is true)
    // live: true, // act on asynchronously loaded content (default is true)
    // resetAnimation: true, // reset animation on end (default is true)
  }).init();
}
