/**
 * @file スライダー
 *
 */

import Swiper, { Navigation } from 'swiper';

export default function () {
  const option = {
    //モジュールを使う
    modules: [Navigation],
    loop: true,
    slidesPerView: 1,
    speed: 300,
    pagination: {
      el: '.swiper-pagination',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      768: {
        slidesPerView: 4,
      },
    },
  };
  const swiper = new Swiper('.swiper', option);
}
